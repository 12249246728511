<template>
    <div class="student-container">
        <div class="left-wrapper">
            <div class="left-header">
                <div class="logo">
                    <i class="iconfont">&#xe600;</i>
                </div>
                <p class="theme">店铺装修</p>
            </div>
            <el-scrollbar class="left-menu-wrapper" :native="false">
                <div class="left-menu">
                    <div class="nav-item" v-if="menuList.length > 0" @click="openRoute(menuItem, menuIndex)"
                         :class="menuItem.state === 2 ? 'is-finish' : menuItem.state === 1 ? 'is-active' : ''"
                         v-for="(menuItem, menuIndex) in menuList"
                         :key="`menuList_${menuIndex}`">
                        <div class="status">
                            <i class="iconfont">&#xe722;</i>
                        </div>
                        <i class="iconfont nav-icon" v-html="menuItem.icon"></i>
                        <span class="text">{{menuItem.name}}</span>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="right-wrapper">
            <div class="right-header">
                <div class="countdown">
                    <!--倒计时-->
                    <CountDown></CountDown>
                </div>
                <div class="user-wrapper">
                    <div class="user-info">
                        <div class="user-avatar">
                            <img :src="userIcon ? userIcon : require('../../../assets/images/user_img.png')" alt="" />
                        </div>
                        <div class="user-name">{{username}}</div>
                    </div>
                    <div class="exit-btn" @click="backTo">
                        <i class="iconfont">&#xe642;</i>
                        <span>提交考卷，并返回主界面</span>
                    </div>
                </div>
            </div>
            <el-scrollbar class="right-content" :native="false">
                <div class="right-wrapper-content">
                    <router-view/>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    import CountDown from '../../../components/student/CountDown.vue'
    import { mapActions } from 'vuex';

    export default {
        data() {
            return {
                username: '',
                userIcon: '',
                ruleDownload: false,
                sucaiDownload: false
            }
        },
        components: {
            CountDown
        },
        computed: {
            menuList() {
                return this.$store.getters.decorationMenuList;
            }
        },
        mounted () {
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            // this.getMenu();
            this.setDecorationMenusList();
            /*this.$lsbridge.subscribe('update', () => {
                this.getMenu();
            })*/
        },
        methods: {
            ...mapActions([
                'setDecorationMenusList'
            ]),
            // 获取左侧菜单列表
            /*getMenu() {
                this.$http.axiosGet(this.$api.decorate_zhuangxiuStateList, (res) => {
                    if (res.code === 200) {
                        this.menuList = res.data;
                        console.log('菜单状态', this.menuList);
                        localStorage.setItem('menuList', JSON.stringify(this.menuList))
                        if (this.menuList[0].state === 0) {
                            this.menuList[0].state = 1
                        }
                        // if (this.menuList[1].state === 2) {
                        //     this.$lsbridge.send('updateStatus');
                        // }
                        if (this.menuList[2].state === 1) {
                            this.$lsbridge.send('updateStatus');
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },*/
            // 打开路由
            openRoute(item, index) {
                if (index !== 0 && this.menuList[index - 1].state !== 2) {
                    this.$message.warning('请完成上一步的内容');
                    return;
                }
                if (index === 0) {
                    if (this.ruleDownload) {
                        /*this.$message({
                            type: 'warning',
                            message: '装修规则已经点击下载了！',
                        });*/
                        this.$confirm('装修规则已经点击下载了，需要再次下载么？', '提示信息', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消'
                        }).then(() => {
                            this.$http.axiosGet(this.$api.decorateFileDownload, (res) => {
                                if (res.code === 200) {
                                    window.open(res.data.rule_file_path);
                                    this.ruleDownload = true;
                                    this.updateStatus(0, 2);
                                }
                            }, (err) => {
                                console.log(err)
                            });
                        }).catch(() => {
                            this.$message.info('已取消');
                        });
                        return;
                    }
                    this.$http.axiosGet(this.$api.decorateFileDownload, (res) => {
                        if (res.code === 200) {
                            window.open(res.data.rule_file_path);
                            this.ruleDownload = true;
                            this.updateStatus(0, 2);
                        }
                    }, (err) => {
                        console.log(err)
                    });
                } else if (index === 1) {
                    if (this.sucaiDownload) {
                        /*this.$message({
                            type: 'warning',
                            message: '装修素材已经点击下载了！',
                        });*/
                        this.$confirm('装修素材已经点击下载了，需要再次下载么？', '提示信息', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消'
                        }).then(() => {
                            this.$http.axiosGet(this.$api.decorateFileDownload, (res) => {
                                if (res.code === 200) {
                                    window.open(res.data.sucai_path);
                                    this.sucaiDownload = true;
                                    this.updateStatus(1, 2);
                                    // this.$lsbridge.send('updateStatus')
                                }
                            }, (err) => {
                                console.log(err)
                            });
                        }).catch(() => {
                            this.$message.info('已取消');
                        });
                        return;
                    }
                    this.$http.axiosGet(this.$api.decorateFileDownload, (res) => {
                        if (res.code === 200) {
                            window.open(res.data.sucai_path);
                            this.sucaiDownload = true;
                            this.updateStatus(1, 2);
                            // this.$lsbridge.send('updateStatus')
                        }
                    }, (err) => {
                        console.log(err)
                    });
                } else if (index === 2) {
                    if (item.state === 0) {
                        this.updateStatus(2, 1);
                    } else {
                        this.$router.push(item.route)
                    }
                } else if (index === 6) {
                    window.open('#' + item.route)
                    if (item.state === 0) {
                        this.updateStatus(6, 2);
                    }
                } else {
                    // 新增的判断
                    if (item.state === 0) {
                        this.updateStatus(index, 1);
                    }
                    this.$router.push(item.route)
                }
            },
            // 更新状态
            updateStatus(index, state) {
                let formData = new FormData();
                formData.append('index', index);
                formData.append('state', state);
                this.$http.axiosPost(this.$api.decorate_changeZhuangxiuState, formData, (res) => {
                    if (res.code === 200) {
                        // this.getMenu()
                        this.setDecorationMenusList();
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 返回主界面
            backTo() {
                this.$router.push('/student/competition/index')
            },
        }
    }
</script>

<style scoped lang="scss">
    .student-container {
        background: #F7F9FB;
        width: 100vw;
        height: 100vh;
        display: flex;
        .left-wrapper {
            width: 280px;
            background: #2C405F;
            height: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            .left-header {
                text-align: center;
                .logo {
                    margin-top: 36px;
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    background: #FD4446;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 60px;
                    .iconfont {
                        display: block;
                        font-size: 33px;
                        color: #fff;
                    }
                }
                .theme {
                    margin-top: 22px;
                    font-size: 28px;
                    color: #fff;
                    line-height: 1;
                }
            }
            .left-menu-wrapper {
                margin-top: 50px;
                height: 1%;
                flex: 1;
                /deep/ .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .left-menu {
                    height: 100%;
                    .nav-item {
                        cursor: pointer;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px;
                        height: 60px;
                        position: relative;
                        margin-bottom: 20px;
                        /*pointer-events: none;*/
                        /*cursor: not-allowed;*/
                        &:before {
                            content: '';
                            position: absolute;
                            left: 48px;
                            bottom: 38px;
                            width: 1px;
                            background: #707C92;
                            height: 64px;
                        }
                        &:first-child {
                            &:before {
                                content: none;
                            }
                        }
                        .status {
                            width: 15px;
                            height: 15px;
                            border: 1px solid #707C92;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .iconfont {
                                font-size: 12px;
                                line-height: 13px;
                                display: none;
                            }
                        }
                        .nav-icon {
                            margin-left: 17px;
                            font-size: 24px;
                            margin-right: 20px;
                            font-weight: normal;
                            transition: all .3s;
                        }
                        .text {
                            font-size: 20px;
                            transition: all .3s;
                        }
                        &.is-finish {
                            cursor: pointer;
                            /*pointer-events: auto;*/
                            &:before {
                                background: #FD4446;
                            }
                            .status {
                                border-color: #FD4446;
                                .iconfont {
                                    display: block;
                                    color: #FD4446;
                                }
                            }
                            &:hover {
                                .nav-icon {
                                    color: #FD4446;
                                }
                                .text {
                                    color: #FD4446;
                                }
                            }
                        }
                        &.is-active {
                            cursor: pointer;
                            /*pointer-events: auto;*/
                            &:before {
                                background: #FD4446;
                            }
                            .status {
                                border-color: #FD4446;
                                background: #FD4446;
                            }
                            &:hover {
                                .nav-icon {
                                    color: #FD4446;
                                }
                                .text {
                                    color: #FD4446;
                                }
                            }
                        }
                    }
                }
            }
        }
        .right-wrapper {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .right-header {
                height: 80px;
                background: #fff;
                border-bottom: 1px solid #D1D2DB;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .countdown {
                    font-size: 18px;
                    color: #FD4446;
                    font-weight: 500;
                    .time {
                        margin-left: 10px;
                    }
                }
                .user-wrapper {
                    position: absolute;
                    right: 30px;
                    display: flex;
                    .user-info {
                        display: flex;
                        align-items: center;
                        .user-avatar {
                            width: 32px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #eee;
                            box-sizing: border-box;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .user-name {
                            margin-left: 6px;
                        }
                    }
                    .exit-btn {
                        margin-left: 24px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: all .3s;
                        .iconfont {
                            font-size: 20px;
                        }
                        span {
                            margin-left: 6px;
                        }
                        &:hover {
                            color: #1122D8;
                        }
                    }
                }
            }
            /deep/ .right-content {
                height: 1%;
                flex: 1;
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        min-width: 900px;
                        /*height: 100%;*/
                    }
                    .right-wrapper-content {
                        padding: 20px 30px 20px 40px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
</style>