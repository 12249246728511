<template>
    <div class="admin-container">
        <div class="header-module">
            <div class="header-logo">管理平台</div>
            <div class="tl-header-right">
                <div class="competition-list-select">
                    <div class="competition_prop">
                        <span>请选择竞赛：</span>
                    </div>
                    <el-select v-model="competition_id" placeholder="请选择" @change="selectCompetitionId">
                        <el-option
                            v-for="item in competitions"
                            :key="item.com_id"
                            :label="item.name"
                            :value="item.com_id">
                        </el-option>
                    </el-select>
                    <el-button type="success" size="mini" class="export-excel" @click="exportScore">导出评分分数表</el-button>
                </div>
                <div class="user-setting">
                    <div class="user-info">
                        <div class="user-avatar">
                            <img :src="userIcon ? userIcon : require('../../assets/images/user_img.png')" alt="" />
                        </div>
                        <div class="user-name">{{username}}</div>
                    </div>
                    <div class="exit-btn" @click="adminLogout">
                        <i class="iconfont">&#xe698;</i>
                        <span>退出</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-module">
            <div class="left-wrapper">
                <el-menu :default-active="menuActive" mode="vertical" class="left-menu">
                    <el-menu-item index="decorationScore" @click="onChangeMenu('decorationScore')" v-if="is_operation">
                        <router-link class="nav-item" :to="{path: '/teacher/index', query: {id: competition_id}}">
                            <i class="iconfont">&#xe600;</i>装修评分
                        </router-link>
                    </el-menu-item>
                    <el-menu-item index="dataAnalysis" @click="onChangeMenu('dataAnalysis')" v-if="is_data_analysis">
                        <router-link class="nav-item" :to="{path: '/teacher/dataAnalysis/index', query: {id: competition_id}}">
                            <i class="iconfont">&#xe600;</i>数据分析模块
                        </router-link>
                    </el-menu-item>
                </el-menu>
            </div>
            <el-scrollbar class="right-wrapper" :native="false">
                <div class="right-wrapper-content">
                    <router-view/>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import {competitionModules} from "@/utils/apis";
import {mapActions} from "vuex";

export default {
    name: "Index",
    data() {
        return {
            menuActive: this.$route.path === '/teacher/index' ? 'decorationScore' : 'dataAnalysis',
            username: '',
            userIcon: '',
            competitions: [],
            competition_id: Number(this.$route.query.id) || null,
            is_operation: false,
            is_data_analysis: false,
        }
    },
    watch: {
        $route(route) {
        },
    },
    mounted () {
        this.username = localStorage.getItem('username');
        let userIcon = localStorage.getItem('headerImg');
        if (userIcon !== 'null') {
            this.userIcon = userIcon;
        }
        if (this.username === null) {
            this.$message({
                type: 'warning',
                message: '登录失效，请重新登录',
                duration: 2000,
                onClose: () => {
                    this.adminLogout();
                }
            });
        }
        this.getCompetitionList();

    },
    created() {
        this.swithModules()
    },
    methods: {
        ...mapActions([
            "COMMITOPERATIONMODULES"
        ]),
        exportScore() {
            if (!this.competition_id) {
                this.$message({
                    type: 'error',
                    message: '请选择比赛',
                    duration: 1500
                });
                return;
            }
            this.$http.asyncGet(this.$api.pw_export, {competition_id: this.competition_id}).then(res => {
                if (res.code === 200) {
                    window.open(res.data.data, '_blank');
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg
                    });
                }
            });
        },
        getCompetitionList() {
            this.$http.axiosGet(this.$api.judgeList,
                (res) => {
                    if (res.code === 200) {
                        this.competitions = res.data;
                        if (!this.competition_id) {
                            this.selectCompetitionId(this.competitions[0].com_id);//获取列表之后默认选中第一场竞赛
                        }
                    } else {
                        this.$message.warning('竞赛列表获取异常');
                    }
                },
                (err) => {
                    console.log(err.response);
                });
        },
        selectCompetitionId(val) {
            this.competition_id = val;
            this.swithModules()
        },
        swithModules() {
            if (this.competition_id) {
                competitionModules(this.competition_id).then(res => {
                    //装修：1  运营：2  客服：3  路演： 4  理论：5  客服分析：6  新客服：7
                    // console.log("res", res.data)
                    if (res.data.indexOf(6) !== -1) {
                        this.is_data_analysis = true
                    } else {
                        this.is_data_analysis = false
                    }
                    let path = ""
                    if (res.data.length && res.data.indexOf(6) !== -1) {
                        this.is_operation = false
                        this.menuActive = "dataAnalysis"
                        path = "/teacher/dataAnalysis/index"
                    } else if(res.data.indexOf(5) !== -1 || res.data.indexOf(1) !== -1 || res.data.indexOf(4) !== -1) {
                        this.is_operation = true
                        this.menuActive = "decorationScore"
                        path = "/teacher/index"
                        this.COMMITOPERATIONMODULES(res.data)
                    }
                    this.$router.push({ path, query: { id: this.competition_id}});
                })
            }
        },
        adminLogout() {
            this.$http.axiosGet(this.$api.out, (res) => {
                //删除所有缓存
                this.$lockr.flush();
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        duration: 1000,
                        message: res.msg,
                        onClose: ()=> {
                            localStorage.removeItem('token');
                            localStorage.removeItem('username');
                            localStorage.removeItem('role');
                            if (this.$route.path === '/'){
                                window.location.reload();
                            } else {
                                this.$router.push('/');
                            }
                        }
                    })
                } else {
                    this.$message({
                        type: 'warning',
                        duration: 1000,
                        message: res.msg,
                        onClose: ()=> {
                            localStorage.removeItem('token');
                            localStorage.removeItem('username');
                            localStorage.removeItem('role');
                            if (this.$route.path === '/'){
                                window.location.reload();
                            } else {
                                this.$router.push('/');
                            }
                        }
                    })
                }
            });
        },
        onChangeMenu (type) {
            this.menuActive = type;
            // console.log({menuActive: this.menuActive});
        }
    }
}
</script>

<style lang="scss" scoped>
.export-excel { margin-left: 10px; }
.admin-container {
    background: #f2f2f2;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .header-module {
        height: 60px;
        display: flex;
        .header-logo {
            width: 260px;
            color: #fff;
            background: #1122D8;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            line-height: 60px;
        }
        .tl-header-right {
            width: 1%;
            flex: 1;
            background: #fff;
            box-shadow:0 0 16px 0 rgba(62,58,234,0.1);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 20px;
            .competition-list-select {
                padding-left: 20px;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .competition_prop {
                    span {
                        font-size: 14px;
                        color: #14141C;
                        font-weight: 600;
                    }
                }
            }
            .user-setting {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .user-info {
                    display: flex;
                    align-items: center;
                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .user-name {
                        margin-left: 6px;
                    }
                }
                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    .iconfont {
                        font-size: 20px;
                    }
                    span {
                        margin-left: 6px;
                    }
                    &:hover {
                        color: #1122D8;
                    }
                }
            }

        }
    }
    .container-module {
        height: 1%;
        flex: 1;
        display: flex;
        .left-wrapper {
            width: 260px;
            background: #fff;
            box-shadow: 0 0 16px 0 rgba(62,58,234,0.1);
            .left-menu {
                .el-menu-item {
                    height: 60px;
                    line-height: 60px;
                    padding: 0!important;
                    .nav-item {
                        height: 100%;
                        color: #878994;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px;
                        position: relative;
                        transition: all .3s;
                        .iconfont {
                            color: #CECDFD;
                            font-size: 20px;
                            margin-right: 20px;
                            transition: all .3s;
                        }
                    }
                    &:focus, &:hover {
                        background-color: transparent;
                        .nav-item {
                            color: #0200D8;
                            .iconfont {
                                color: #0200D8;
                            }
                        }
                    }
                    &.is-active {
                        background: #F2F3FA;
                        .nav-item {
                            color: #0200D8;
                            &:before {
                                content: '';
                                width: 4px;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background: #1122D8;
                            }
                            .iconfont {
                                color: #0200D8;
                            }
                        }
                    }
                }
            }
        }
        /deep/ .right-wrapper {
            width: 1%;
            flex: 1;
            & > .el-scrollbar__wrap {
                overflow-x: hidden;
                & > .el-scrollbar__view {
                    height: 100%;
                    min-width: 900px;
                    .right-wrapper-content {
                        padding: 20px;
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
</style>
