<template>
  <div class="admin-container">
    <div class="header-module">
      <div class="header-logo">管理平台</div>
      <div class="header-right">
        <div class="user-info">
          <div class="user-avatar">
            <img :src="userIcon ? userIcon : require('../../assets/images/user_img.png')" alt="" />
          </div>
          <div class="user-name">{{ username }}</div>
        </div>
        <div class="exit-btn" @click="adminLogout">
          <i class="iconfont">&#xe698;</i>
          <span>退出</span>
        </div>
      </div>
    </div>
    <div class="container-module">
      <div class="left-wrapper">
        <el-menu :default-active="menuActive" mode="vertical" class="left-menu">
          <el-menu-item index="dataCenter">
            <router-link class="nav-item" to="/admin/datacenter"> <i class="iconfont">&#xe605;</i>数据中心 </router-link>
          </el-menu-item>
          <el-menu-item index="adminIndex">
            <router-link class="nav-item" to="/admin/index"> <i class="iconfont">&#xe607;</i>教务管理 </router-link>
          </el-menu-item>
          <el-menu-item index="competition">
            <router-link class="nav-item" to="/admin/competition"> <i class="iconfont">&#xe703;</i>竞赛模板管理 </router-link>
          </el-menu-item>
          <el-menu-item index="operateManage">
            <router-link class="nav-item" to="/admin/operatedata/index"> <i class="iconfont">&#xe63c;</i>运营推广管理 </router-link>
          </el-menu-item>
          <el-menu-item index="storeCustomer">
            <router-link class="nav-item" to="/admin/storecustomer"> <i class="iconfont">&#xe60c;</i>店铺客服管理 </router-link>
          </el-menu-item>
          <el-menu-item index="decorationManage">
            <router-link class="nav-item" to="/admin/decorationdata"> <i class="iconfont">&#xe600;</i>店铺装修管理 </router-link>
          </el-menu-item>
          <el-menu-item index="theoryManage">
            <router-link class="nav-item" to="/admin/theorymanage"> <i class="iconfont">&#xe640;</i>理论考试管理 </router-link>
          </el-menu-item>
          <el-menu-item index="rolePermissions">
            <router-link class="nav-item" to="/admin/rolepermissions"> <i class="iconfont">&#xe69b;</i>角色权限管理 </router-link>
          </el-menu-item>
<!--          <el-menu-item index="aliossManager">-->
<!--            <router-link class="nav-item" to="/admin/aliossmanager"> <i class="iconfont">&#xe7fe;</i>阿里云OSS管理 </router-link>-->
<!--          </el-menu-item>-->
          <el-menu-item index="webSetting">
            <router-link class="nav-item" to="/admin/web"> <i class="iconfont">&#xe632;</i>网站应用设置 </router-link>
          </el-menu-item>
          <el-menu-item index="dataAnalysis">
            <router-link class="nav-item" to="/admin/dataTemplateManager"> <i class="iconfont">&#xe8b9;</i>数据分析管理 </router-link>
          </el-menu-item>
           <el-menu-item index="newkefuManage">
            <router-link class="nav-item" to="/admin/newstorecustomer"> <i class="iconfont">&#xe60c;</i>新店铺客服管理 </router-link>
          </el-menu-item>
        </el-menu>
      </div>
      <el-scrollbar class="right-wrapper" :native="false">
        <div class="right-wrapper-content">
          <keep-alive>
            <router-view />
          </keep-alive>

        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name:'adminLayoutTab',
  data() {
    return {
      menuActive: "",
      username: "",
      userIcon: "",
    };
  },
  watch: {
    $route(route) {
      this.menuActive = route.matched[1].name;
      let serviceUrl = ["serviceDataCreation", "serviceCmmodityCreation"];
      if (route.matched[1].name === "dataCenterDetail") {
        this.menuActive = "dataCenter";
      } else {
        serviceUrl.map((item) => {
          if (route.matched[1].name == item) {
            this.menuActive = "storeCustomer";
          }
        });
      }
    },
  },
  mounted() {
    this.username = localStorage.getItem("username");
    let userIcon = localStorage.getItem("headerImg");
    if (userIcon !== "null") {
      this.userIcon = userIcon;
    }
    if (this.username === null) {
      this.$message.warning("请先登录！");
      this.$router.push("/");
    }
  },
  created() {
    this.menuActive = this.$route.matched[1].name;
    let serviceUrl = ["serviceDataCreation", "serviceCmmodityCreation"];
    if (this.$route.matched[1].name === "dataCenterDetail") {
      this.menuActive = "dataCenter";
    } else {
      serviceUrl.map((item) => {
        if (this.$route.matched[1].name == item) {
          this.menuActive = "storeCustomer";
        }
      });
    }
  },
  methods: {
    adminLogout() {
      this.$http.axiosGet(this.$api.out, (res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            duration: 1000,
            message: res.msg,
            onClose: () => {
              localStorage.removeItem("token");
              localStorage.removeItem("username");
              localStorage.removeItem("role");
              if (this.$route.path === "/") {
                window.location.reload();
              } else {
                this.$router.push("/");
              }
            },
          });
        } else {
          this.$message({
            type: "warning",
            duration: 1000,
            message: res.msg,
            onClose: () => {
              localStorage.removeItem("token");
              localStorage.removeItem("username");
              localStorage.removeItem("role");
              if (this.$route.path === "/") {
                window.location.reload();
              } else {
                this.$router.push("/");
              }
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-container {
  background: #f2f2f2;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header-module {
    height: 60px;
    display: flex;

    .header-logo {
      width: 260px;
      color: #fff;
      background: #1122d8;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      line-height: 60px;
    }

    .header-right {
      width: 1%;
      flex: 1;
      background: #fff;
      box-shadow: 0 0 16px 0 rgba(62, 58, 234, 0.1);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;

      .user-info {
        display: flex;
        align-items: center;

        .user-avatar {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #eee;
          box-sizing: border-box;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .user-name {
          margin-left: 6px;
        }
      }

      .exit-btn {
        margin-left: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;

        .iconfont {
          font-size: 20px;
        }

        span {
          margin-left: 6px;
        }

        &:hover {
          color: #1122d8;
        }
      }
    }
  }

  .container-module {
    height: 1%;
    flex: 1;
    display: flex;

    .left-wrapper {
      width: 260px;
      background: #fff;
      box-shadow: 0 0 16px 0 rgba(62, 58, 234, 0.1);

      .left-menu {
        .el-menu-item {
          height: 60px;
          line-height: 60px;
          padding: 0 !important;

          .nav-item {
            height: 100%;
            color: #878994;
            font-size: 18px;
            display: flex;
            align-items: center;
            padding: 0 20px 0 40px;
            position: relative;
            transition: all 0.3s;

            .iconfont {
              color: #cecdfd;
              font-size: 20px;
              margin-right: 20px;
              transition: all 0.3s;
            }
          }

          &:focus,
          &:hover {
            background-color: transparent;

            .nav-item {
              color: #0200d8;

              .iconfont {
                color: #0200d8;
              }
            }
          }

          &.is-active {
            background: #f2f3fa;

            .nav-item {
              color: #0200d8;

              &:before {
                content: "";
                width: 4px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #1122d8;
              }

              .iconfont {
                color: #0200d8;
              }
            }
          }
        }
      }
    }

    ::v-deep .right-wrapper {
      width: 1%;
      flex: 1;

      & > .el-scrollbar__wrap {
        overflow-x: hidden;

        & > .el-scrollbar__view {
          height: 100%;
          min-width: 900px;

          .right-wrapper-content {
            padding: 20px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
</style>
