<template>
    <div class="BoothAdvertisingAdd">
        <div class="BoothAdvertisingAddHeader">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>搜索</el-breadcrumb-item>
                <el-breadcrumb-item>推广管理</el-breadcrumb-item>
                <el-breadcrumb-item>新建计划</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="BoothAdvertisingAdd-box">
            <div class="BoothAdvertisingAdd-left">
                <div class="left-creation">
                    <p>01 <span>创建计划</span></p>
                    <div class="left-step">
                        <div class="stepOne">
                            <span class="stepActive"></span>
                            <span>推广首页</span>
                        </div>
                        <div :class="stepActive>1?'stepXian xianActive':'stepXian'">
                            <span v-show="stepActive > 1">店铺推广</span>
                            <span class="progress-btn" v-show="stepActive > 1" @click="editStep(1)">修改</span>
                        </div>
                        <div class="stepOne">
                            <span :class="stepActive >1 ? 'stepActive' : ''"></span>
                            <span>计划设置</span>
                        </div>
                        <div v-show="stepActive>2" class="stepXian" style="border: none;padding-bottom: 0">
                            <span>统一日预算：{{searchBudget}}</span>
                            <span class="progress-btn" @click="editStep(2)">修改</span>
                        </div>

                    </div>
                    <div class="left-stepTwo">
                        <p style="padding-top: 40px">02 <span>创建单元</span></p>
                        <div class="left-step">
                            <div class="stepOne">
                                <span :class="stepActive >2 ? 'stepActive' : ''"></span>
                                <span>选择商品</span>
                            </div>
                            <div :class="stepActive>2?'stepXian xianActive':'stepXian'"></div>
                            <div class="stepOne">
                                <span :class="stepActive >2 ? 'stepActive' : ''"></span>
                                <span>推广设置</span>
                            </div>
                        </div>
                    </div>
                    <div class="left-succeed">
                        <p style="padding-top: 40px">03 <span>完成推广</span></p>
                    </div>
                </div>
            </div>
            <div class="BoothAdvertisingAdd-right">
                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "studentCreatePlanLayout",
        data(){
            return {
                stepActive:1,
                searchBudget: ''
            }
        },
        created() {
            this.refreshPageRoute()
            if(this.$lockr.get('planTwo')){
                let budgetData = this.$lockr.get('planTwo');
                if(budgetData.search_budget == 0){
                    this.searchBudget = '不限'
                } else {
                    this.searchBudget = budgetData.search_budget
                }
            }
        },
        watch: {
            $route(route){
                if(route.name === 'CreatePlanStepTwo'){
                    this.stepActive = 2
                } else if(route.name === 'CreatePlanStepOne'){
                    this.stepActive = 1;
                } else if(route.name === 'CreatePlanStepThree'){
                    this.stepActive = 3;
                }
                if(this.$lockr.get('planTwo')){
                    let budgetData = this.$lockr.get('planTwo');
                    if(budgetData.search_budget  == 0){
                        this.searchBudget = '不限'
                    } else {
                        this.searchBudget = budgetData.search_budget
                    }
                }
            }
        },
        methods: {
            refreshPageRoute(){
                if(this.$route.name === 'CreatePlanStepTwo'){
                    this.stepActive = 2
                } else if(this.$route.name === 'CreatePlanStepOne'){
                    this.stepActive = 1;
                } else if(this.$route.name === 'CreatePlanStepThree' || this.$route.name === 'PlanStepSucceed'){
                    this.stepActive = 3;
                }
            },
            //修改店铺推广
            editStep(step=1) {
                switch (step) {
                    case 1:
                        if (this.$route.query.id) {
                            this.$router.push({
                                path:'/student/operationpromotion/searchads/createplanstepone',
                                query: {
                                    id: this.$route.query.id
                                }
                            });
                            return;
                        }
                        this.$router.push('/student/operationpromotion/searchads/createplanstepone');
                        break;
                    case 2:
                        let router = {
                            path: '/student/operationpromotion/searchads/createplansteptwo',
                        }
                        if (this.$route.query.id) {
                            router.query = {
                                id: this.$route.query.id
                            }
                        }
                        this.$router.push(router);
                        break;
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .progress-btn {
        cursor: pointer;
    }
    .stepActive{
        background: #20D08C;
        border-color: #20D08C!important;
    }
    .xianActive{
        border-color: #20D08C!important;
    }
    .BoothAdvertisingAdd{
        height: 100%;
        /*padding: 20px 40px;*/
        background: #F7F9FB;
        .BoothAdvertisingAddHeader{
            span{
                font-size: 16px;
            }
        }
        .BoothAdvertisingAdd-box{
            display: flex;
            .BoothAdvertisingAdd-left{
                width: 190px;
                .left-creation{
                    display: flex;
                    flex-direction: column;
                    p:nth-child(1){
                        margin: 0;
                        padding: 20px 0;
                        line-height:1;
                        color: #333333;
                        font-size: 48px;
                        span{
                            font-size: 24px;
                        }
                    }
                    .left-step{
                        .stepOne{
                            line-height: 0;
                            display: flex;
                            align-items: center;
                            padding-left: 10px;
                            span{
                                display: inline-block;
                            }
                            span:nth-child(1){
                                width: 14px;
                                height: 14px;
                                border: 1px solid #DFDFDF;
                                border-radius: 50%;
                            }
                            span:nth-child(2){
                                margin-left: 16px;
                                font-size: 20px;
                            }
                        }
                        .stepXian{
                            border-left:1px solid #DFDFDF;
                            margin-left: 17px;
                            line-height: 1;
                            padding-left: 23px;
                            padding-top: 10px;
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 30px;
                            span:nth-child(1){
                                color: #333333;
                                font-size: 14px;
                            }
                            span:nth-child(2){
                                margin-top: 6px;
                                color: #1E63F1;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .BoothAdvertisingAdd-right{
                width: calc(100% - 230px);
                /*height: 100%;*/
                background: #ffffff;
                margin-top: 20px;
            }
        }
    }
</style>