<template>
    <div class="student-container">
        <div class="left-wrapper">
            <div class="left-header">
                <div class="logo">
                    <i class="iconfont">&#xe63c;</i>
                </div>
                <p class="theme">运营推广</p>
            </div>
            <el-scrollbar class="left-menu-wrapper" :native="false">
                <div class="left-menu">
                    <div class="nav-item"
                        :class="getClass(menuItem)"
                         v-for="(menuItem, menuIndex) in menuList"
                         :key="`menuList_${menuIndex}`">
                        <div class="item-fa">
                            <div class="status">
                                <i class="iconfont">&#xe722;</i>
                            </div>
                            <i class="iconfont nav-icon" v-html="menuItem.icon"></i>
                            <span class="text">{{menuItem.name}}</span>
                        </div>
                        <div class="item-ch" v-for="(childrenItem, childrenIndex) in menuItem.children"
                             :class="Number(childrenItem.status) === 2 ? 'finished' : Number(childrenItem.status) === 1 ? 'actived' : ''">
                            <div class="status ch-status">
                                <span class="line-right"></span>
                                <i class="iconfont">&#xe722;</i>
                                <span class="line-left"></span>
                            </div>
                            <span class="text" @click="openRoute(childrenItem, childrenIndex, menuIndex)">{{childrenItem.name}}</span>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="right-wrapper">
            <div class="right-header">
                <div class="countdown">
                    <!--倒计时-->
                    <CountDown></CountDown>
                </div>
                <div class="user-wrapper">
                    <div class="user-info">
                        <div class="user-avatar">
                            <img :src="userIcon ? userIcon : require('../../../assets/images/user_img.png')" alt="" />
                        </div>
                        <div class="user-name">{{username}}</div>
                    </div>
                    <div class="exit-btn" @click="backTo">
                        <i class="iconfont">&#xe642;</i>
                        <span>提交考卷，并返回主界面</span>
                    </div>
                </div>
            </div>
            <el-scrollbar class="right-content" :native="false">
                <div class="right-wrapper-content">
                    <router-view/>
                </div>
            </el-scrollbar>
            <el-dialog
                    title="提示"
                    :visible.sync="schoolDialogVisible"
                    width="608px">
                <div class="train-dialog-content">
                    <span>1. 点击确定提交答案并返回首页，您可以重新进来编辑提交，成绩以最后提交为准</span>
                    <span>2. 点击取消继续留在当前页面作答</span>
                    <span>3. <b style="color: red">*注意：</b>店铺引流-搜索广告-推广管理：创建好的推广计划，要先点击结束推广，才算完成答题，可以重新编辑后重新结束推广</span>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button @click="schoolDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="schoolEndPromotion" class="btn-red">确 定</el-button>
            </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import _ from "underscore";
    import CountDown from '../../../components/student/CountDown.vue';
    import { mapActions } from 'vuex';

    export default {
        name: "SchoolAdminLayout",
        data() {
            return {
                username: '',
                userIcon: '',
                schoolDialogVisible: false,
                cehuaDownload: false
            }
        },
        computed: {
            menuList() {
                return this.$store.getters.dataMenuList;
            }
        },
        components: {
            CountDown
        },
        created() {
            this.setDataMenusList();
        },
        mounted () {
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            // this.getMenu();
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            getClass(item) {
                let arr = 0;
                let finish_arr = [];
                _.each(item.children, (item) => {
                    if (Number(item.status) === 2) {
                        finish_arr.push(item);
                    }
                    if (Number(item.status) === 1 || Number(item.status) === 2) {
                        arr++;
                    }
                });
                if (finish_arr.length === item.children.length) {
                    return 'is-finish';
                } else if (arr !== 0) {
                    return 'is-active';
                }

            },
            // 获取左侧菜单列表
            /*getMenu() {
                this.$http.axiosGet(this.$api.data_userOpList, (res) => {
                    if (res.code === 200) {
                        this.menuList = res.data;
                        this.$lockr.set('menu', this.menuList);
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },*/
            // 打开路由
            openRoute(item, index, mindex) {
                if (index === 0 && mindex !== 0) {
                    let child = this.menuList[mindex-1].children;
                    let lastChild = child[child.length - 1];
                    if (Number(lastChild.status) === 2) {
                        // if (item.name === '活动策划方案下载') {
                        if (mindex === 1) {
                            if (this.cehuaDownload) {
                                /*this.$message({
                                    type: 'warning',
                                    message: '活动策划方案已经点击下载！',
                                });*/
                                this.$confirm('活动策划方案已经点击下载了，需要再次下载么？', '提示信息', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消'
                                }).then(() => {
                                    this.$http.axiosGet(this.$api.showStoreMarket, (res) => {
                                        if (res.code === 200) {
                                            window.open(res.data.data[0].store_file.src);
                                            this.cehuaDownload = true;
                                            this.operation_updateStatus(1, index, 2);
                                        } else {
                                            this.$message.warning(res.msg)
                                        }
                                    }, (err) => {
                                        console.log(err)
                                    })
                                }).catch(() => {
                                    this.$message.info('已取消');
                                });
                                return;
                            }
                            this.$http.axiosGet(this.$api.showStoreMarket, (res) => {
                                if (res.code === 200) {
                                    window.open(res.data.data[0].store_file.src);
                                    this.cehuaDownload = true;
                                    this.operation_updateStatus(1, index, 2);
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else if (Number(lastChild.status) !== 2) {
                        this.$message.warning('请完成上一步的内容');
                        return;
                    }
                } else {
                    if (index !== 0) {
                        let child = this.menuList[mindex].children;
                        let prevChild = child[index-1];
                        if (Number(prevChild.status) !== 2) {
                            this.$message.warning('请完成上一步的内容');
                            return;
                        }
                    }
                }
                if (item.name === '行业数据' || item.name === '商品榜单' || item.name === '热门关键词') {
                    let routeUrl = this.$router.resolve({
                        path: item.route
                    });
                    window.open(routeUrl .href, '_blank');
                    this.operation_updateStatus(0, index, 2);
                } else if (item.name === '展位数据分析' || item.name === '搜索数据分析') {
                    let routeUrl = this.$router.resolve({
                        path: item.route
                    });
                    window.open(routeUrl .href, '_blank');
                    this.operation_updateStatus(2, index, 2);
                } else if (item.name === '搜索热门关键词') {
                    let routeUrl = this.$router.resolve({
                        path: '/student/operationpromotion/shopcustomeranalysis?type=3'
                    });
                    window.open(routeUrl .href, '_blank');
                    this.operation_updateStatus(2, index, 2);
                } else if (Number(this.menuList[0].children[1].status) === 2 && item.name === '商品采购') {
                    this.$router.push(item.route);
                    setTimeout(()=> {
                        window.location.reload();
                    }, 10);

                } else {
                    this.$router.push(item.route);
                    // this.operation_updateStatus(1, index, 2);
                }
            },
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        // this.getMenu();
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 返回主界面
            backTo() {
                // this.$router.push('/student/index')
                let admin_count = Number(localStorage.getItem('admin_count'))
                if (admin_count) {
                    let goods_count = this.$lockr.get('goods_count');
                    if ((!goods_count && Number(goods_count) !== 0) || Number(goods_count) === admin_count) {
                        this.$message.warning('请在店铺选品-商品管理-结束优化标题');
                        return ;
                    }

                    let zw_count = this.$lockr.get('zw_count');
                    if ((!zw_count && Number(zw_count) !== 0) || Number(zw_count) === admin_count) {
                        this.$message.warning('请在店铺引流-展位广告-推广计划中结束推广');
                        return ;
                    }

                    let tg_count = this.$lockr.get('tg_count');
                    if ((!tg_count && Number(tg_count) !== 0) || Number(tg_count) === admin_count) {
                        this.$message.warning('请在店铺引流-搜索广告-推广计划中结束推广');
                        return;
                    }
                }
                this.schoolDialogVisible = true;
            },
            //点击返回主界面时，选择要不要结束推广，确定时调用
            schoolEndPromotion() {
                let formData = new FormData();
                this.$http.axiosPost(this.$api.storedrainage_endoperation, formData, (res) => {
                    if (res.code === 200) {
                        this.schoolDialogVisible = false;
                        this.$router.push('/student/competition/index');
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .student-container {
        background: #F7F9FB;
        width: 100vw;
        height: 100vh;
        display: flex;
        .left-wrapper {
            width: 280px;
            background: #2C405F;
            height: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            .left-header {
                text-align: center;
                .logo {
                    margin-top: 36px;
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    background: #FD4446;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 60px;
                    .iconfont {
                        display: block;
                        font-size: 33px;
                        color: #fff;
                    }
                }
                .theme {
                    margin-top: 22px;
                    font-size: 28px;
                    color: #fff;
                    line-height: 1;
                }
            }
            .left-menu-wrapper {
                margin-top: 50px;
                height: 1%;
                flex: 1;
                /deep/ .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .left-menu {
                    height: 100%;
                    .nav-item {
                        color: #fff;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0 20px 0 40px;
                        /*height: 60px;*/
                        position: relative;
                        margin-bottom: 20px;
                        /*pointer-events: none;*/
                        /*cursor: not-allowed;*/
                        &:before {
                            content: '';
                            position: absolute;
                            left: 48px;
                            bottom: 125px;
                            width: 1px;
                            background: #707C92;
                            height: 32px;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            left: 48px;
                            bottom: 125px;
                            width: 29px;
                            background: #707C92;
                            height: 1px;
                        }
                        &:first-child {
                            &:before {
                                content: '';
                                position: absolute;
                                left: 48px;
                                bottom: 163px;
                                width: 1px;
                                background: #707C92;
                                height: 32px;
                            }
                            &:after {
                                bottom: 163px;
                            }
                        }
                        &:last-child {
                            &:before {
                                content: '';
                                position: absolute;
                                left: 48px;
                                bottom: 163px;
                                width: 1px;
                                background: #707C92;
                                height: 32px;
                            }
                            &:after {
                                bottom: 163px;
                            }
                        }
                        .item-fa {
                            display: flex;
                            align-items: center;
                            width: 100%;
                        }
                        .item-ch {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            color: #F7F9FB;
                            width: 100%;
                            margin-top: 15px;
                            &:nth-child(2) {
                                .status {
                                    .line-right {
                                        width: 1px;
                                        height: 22px;
                                        background: #707C92;
                                        position: absolute;
                                        z-index: 999;
                                        margin-right: 0;
                                        margin-bottom: -37px;
                                    }
                                }
                            }
                            &:last-child {
                                .status {
                                    .line-right {
                                        display: none;
                                    }
                                }
                            }
                            .status {
                                margin: 0 26px 0 38px;
                                .line-right {
                                    width: 1px;
                                    height: 22px;
                                    background: #707C92;
                                    position: absolute;
                                    z-index: 999;
                                    margin-right: 0;
                                    margin-bottom: -37px;
                                }
                                .line-left {
                                    width: 12px;
                                    height: 1px;
                                    background: #707C92;
                                    position: absolute;
                                    z-index: 999;
                                    margin-left: 29px;
                                }
                            }
                            .text {
                                color: #F7F9FB;
                                font-size: 16px;
                                /*&:before {*/
                                    /*content: '';*/
                                    /*position: absolute;*/
                                    /*left: 86px;*/
                                    /*bottom: 134px;*/
                                    /*width: 1px;*/
                                    /*background: #707C92;*/
                                    /*height: 21px;*/
                                /*}*/
                                /*&:after {*/
                                    /*content: '';*/
                                    /*position: absolute;*/
                                    /*left: 95px;*/
                                    /*bottom: 163px;*/
                                    /*width: 12px;*/
                                    /*background: #707C92;*/
                                    /*height: 1px;*/
                                /*}*/
                            }
                            /*&:nth-child(3) {*/
                                /*&:before {*/
                                    /*bottom: 134px;*/
                                /*}*/
                                /*&:after {*/
                                    /*bottom: 163px;*/
                                /*}*/
                            /*}*/
                            &.finished {
                                cursor: pointer;
                                /*pointer-events: auto;*/
                                &:before {
                                    background: #FD4446;
                                }
                                &:after {
                                    background: #FD4446;
                                }
                                .status {
                                    border-color: #FD4446;
                                    background: transparent;
                                    .iconfont {
                                        display: block;
                                        color: #FD4446;
                                    }
                                }
                                &:hover {
                                    .nav-icon {
                                        color: #FD4446;
                                    }
                                }
                                .status {
                                    .line-right {
                                        background: #FD4446;
                                    }
                                    .line-left {
                                        background: #FD4446;
                                    }
                                }
                                &:nth-child(2) {
                                    .status {
                                        .line-right {
                                            background: #FD4446;
                                        }
                                        .line-left {
                                            background: #FD4446;
                                        }
                                    }
                                }
                                .item-ch {
                                    &:hover {
                                        .text {
                                            color: #FD4446;
                                        }
                                    }
                                }
                                .text {
                                    &:before {
                                        background: #FD4446;
                                    }
                                    &:after {
                                        background: #FD4446;
                                    }
                                }
                            }
                            &.actived {
                                cursor: pointer;
                                /*pointer-events: auto;*/
                                &:before {
                                    background: #FD4446;
                                }
                                &:after {
                                    background: #FD4446;
                                }
                                /*.status {*/
                                    /*border-color: #FD4446;*/
                                    /*background: #FD4446;*/
                                /*}*/
                                .ch-status {
                                    border-color: #FD4446;
                                    background: #FD4446;
                                    .line-left {
                                        background: #FD4446;
                                    }
                                }
                                .item-fa {
                                    .status {
                                        border-color: #FD4446;
                                        background: #FD4446;
                                    }
                                }
                                &:hover {
                                    .nav-icon {
                                        color: #FD4446;
                                    }
                                }
                                .item-ch {
                                    &:hover {
                                        .text {
                                            color: #FD4446;
                                        }
                                    }
                                }
                            }
                        }
                        .status {
                            width: 15px;
                            height: 15px;
                            border: 1px solid #707C92;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .iconfont {
                                font-size: 12px;
                                line-height: 13px;
                                display: none;
                            }
                        }
                        .nav-icon {
                            margin-left: 17px;
                            font-size: 24px;
                            margin-right: 20px;
                            font-weight: normal;
                            transition: all .3s;
                        }
                        .text {
                            font-size: 20px;
                            transition: all .3s;
                        }
                        &.is-finish {
                            cursor: pointer;
                            /*pointer-events: auto;*/
                            &:before {
                                background: #FD4446;
                            }
                            &:after {
                                background: #FD4446;
                            }
                            .status {
                                border-color: #FD4446;
                                background: transparent;
                                .iconfont {
                                    display: block;
                                    color: #FD4446;
                                }
                            }
                            &:hover {
                                .nav-icon {
                                    color: #FD4446;
                                }
                            }
                            .item-ch {
                                &:hover {
                                    .text {
                                        color: #FD4446;
                                    }
                                }
                            }
                        }
                        &.is-active {
                            cursor: pointer;
                            /*pointer-events: auto;*/
                            &:before {
                                background: #FD4446;
                            }
                            &:after {
                                background: #FD4446;
                            }
                            /*.status {*/
                                /*border-color: #FD4446;*/
                                /*background: #FD4446;*/
                            /*}*/
                            .item-fa {
                                .status {
                                border-color: #FD4446;
                                background: #FD4446;
                                }
                            }
                            .item-ch {
                                &:hover {
                                    .text {
                                        color: #FD4446;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .right-wrapper {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .right-header {
                height: 80px;
                background: #fff;
                border-bottom: 1px solid #D1D2DB;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .countdown {
                    font-size: 18px;
                    color: #FD4446;
                    font-weight: 500;
                    .time {
                        margin-left: 10px;
                    }
                }
                .user-wrapper {
                    position: absolute;
                    right: 30px;
                    display: flex;
                    .user-info {
                        display: flex;
                        align-items: center;
                        .user-avatar {
                            width: 32px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #eee;
                            box-sizing: border-box;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .user-name {
                            margin-left: 6px;
                        }
                    }
                    .exit-btn {
                        margin-left: 24px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: all .3s;
                        .iconfont {
                            font-size: 20px;
                        }
                        span {
                            margin-left: 6px;
                        }
                        &:hover {
                            color: #1122D8;
                        }
                    }
                }
            }
            /deep/ .right-content {
                height: 1%;
                flex: 1;
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        min-width: 900px;
                        /*height: 100%;*/
                    }
                    .right-wrapper-content {
                        padding: 20px 30px 20px 40px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
    .train-dialog-content {
        display: flex;
        flex-direction: column;
        line-height: 2;
    }
</style>